import { Account } from '@Types/account/Account'
import { Address } from '@Types/account/Address'
import { Auth0ChangePasswordProps, UserIdentity, UserMetaData } from '@Types/account/Auth0'
import { NekomAddressResponse } from '@Types/account/Nekom'
import { CTResponse } from '@Types/response'
import useSWR, { mutate } from 'swr'
import { REMEMBER_ME } from 'helpers/constants/localStorage'
import { PatientDetailsProps } from 'types/checkout'
import { revalidateOptions } from 'frontastic'
import { fetchApiHub, ResponseError } from 'frontastic/lib/fetch-api-hub'

export interface GetAccountResult {
  loggedIn: boolean;
  account?: Account;
  error?: ResponseError;
  isValidating?: boolean;
}

export interface UpdateAccount {
  firstName?: string;
  lastName?: string;
  salutation?: string;
  birthdayYear?: number;
  birthdayMonth?: number;
  birthdayDay?: number;
  phone?: string;
  profileId?: string;
  deleteBirthDay?: boolean;
}

export interface RegisterAccount extends UpdateAccount {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  billingAddress?: Address;
  shippingAddress?: Address;
}

export const getAccount = (): GetAccountResult => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const result = useSWR<Account | GetAccountResult>('/action/account/getAccount', fetchApiHub, revalidateOptions)

  const account = (result.data as GetAccountResult)?.account || (result.data as Account)

  if (account?.accountId && account?.confirmed) return { account, isValidating: result.isValidating, loggedIn: true }

  return {
    loggedIn: false,
    isValidating: result.isValidating,
    account: undefined,
    error: result.error,
  }
}

export const login = async (
  email: string,
  password: string,
  remember?: boolean
): Promise<Account> => {
  const payload = {
    email,
    password,
  }
  if (remember) window.localStorage.setItem(REMEMBER_ME, '1')
  const res = await fetchApiHub('/action/account/login', { method: 'POST' }, payload)
  await mutate('/action/account/getAccount', res)
  await mutate('/action/cart/getCart')
  // await mutate('/action/wishlist/getWishlist')
  return res
}
export const logout = async () => {
  window.localStorage.removeItem(REMEMBER_ME)
  const res = await fetchApiHub('/action/account/logout', { method: 'POST' })
  await mutate('/action/account/getAccount', res)
  await mutate('/action/cart/getCart')
  // await mutate('/action/wishlist/getWishlist')
  return res
}

export const register = async (account: RegisterAccount): Promise<Account> => {
  const response = await fetchApiHub('/action/account/register', { method: 'POST' }, account)
  return response
}

export const confirm = async (token: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/confirm', { method: 'POST' }, { token })
  await mutate('/action/account/getAccount', res)
  return res
}

export const requestConfirmationEmail = async (email: string, password: string): Promise<void> => {
  const payload = {
    email,
    password,
  }
  const res = await fetchApiHub('/action/account/requestConfirmationEmail', { method: 'POST' }, payload)
  return res
}

export const changePassword = async (oldPassword: string, newPassword: string): Promise<Account> => {
  return await fetchApiHub('/action/account/password', { method: 'POST' }, { oldPassword, newPassword })
}

export const requestPasswordReset = async (email: string): Promise<Response> => {
  const payload = {
    email,
  }

  return await fetchApiHub('/action/account/requestReset', { method: 'POST' }, payload)
}

export const resetPassword = async (token: string, newPassword: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/reset', { method: 'POST' }, { token, newPassword })
  await mutate('/action/account/getAccount', res)
  return res
}

export const update = async (account: UpdateAccount): Promise<Account> => {
  const res = await fetchApiHub('/action/account/update', { method: 'POST' }, account)
  await mutate('/action/account/getAccount', res)
  return res
}

export const addAddress = async (address: Omit<Address, 'addressId'>): Promise<Account> => {
  const res = await fetchApiHub('/action/account/addAddress', { method: 'POST' }, address)
  await mutate('/action/account/getAccount', res)
  return res
}

export const updateAddress = async (address: Address): Promise<Account> => {
  const res = !address?.addressId
    ? await fetchApiHub('/action/account/addAddress', { method: 'POST' }, address)
    : await fetchApiHub('/action/account/updateAddress', { method: 'POST' }, address)
  await mutate('/action/account/getAccount')
  return res
}

export const removeAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/removeAddress', { method: 'POST' }, { addressId })
  await mutate('/action/account/getAccount', res)
  return res
}

export const setDefaultBillingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultBillingAddress', { method: 'POST' }, { addressId })
  await mutate('/action/account/getAccount', res)
  return res
}

export const setDefaultShippingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultShippingAddress', { method: 'POST' }, { addressId })
  await mutate('/action/account/getAccount', res)
  return res
}

export const updateCustomerDetails = async (patientData: PatientDetailsProps): Promise<Account> => {
  const payload = {
    firstName: patientData.patientFirstName,
    lastName: patientData.patientLastName,
    customerPhone: `(${patientData.patientCountryPhoneCode}) ${patientData.patientPhone}`,
  }
  const res = await fetchApiHub('/action/account/updateCustomerDetails', { method: 'POST' }, payload)
  await mutate('/action/account/getAccount', res)
  return res
}

export const validateAddress = async (address: Address): Promise<NekomAddressResponse> => {
  const res = await fetchApiHub('/action/account/validateAddress', { method: 'POST' }, address)
  return res
}

export const triggerPasswordChange = async (data: Auth0ChangePasswordProps): Promise<any> => {
  const res = await fetchApiHub('/action/account/triggerPasswordChange', { method: 'POST' }, data)
  return res
}

export const sendAccountDeletionRequest = async (): Promise<CTResponse> => {
  const res = await fetchApiHub('/action/account/sendAccountDeletionRequest', { method: 'POST' })

  // if (res?.success) {
  //   await mutate('/action/account/getAccount', res)
  //   await mutate('/action/cart/getCart')
  // }

  return res
}

export const createEmailVerificationTicket = async (payload: UserIdentity): Promise<CTResponse> => {
  const res = await fetchApiHub('/action/account/createEmailVerificationTicket', { method: 'POST' }, payload)

  return res
}

export const updateSupportConsent = async (payload: UserMetaData): Promise<CTResponse> => {
  const res = await fetchApiHub('/action/account/updateSupportConsent', { method: 'POST' }, payload)

  return res
}

export const getUserMetaData = async (payload: UserMetaData): Promise<CTResponse> => {
  const res = await fetchApiHub('/action/account/getUserMetaData', { method: 'POST' }, payload)

  return res
}